import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { PlusIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import { useParams, useNavigate } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import dayjs from 'dayjs'

// Components
import { Button } from '../../components/Button'
import { CheckListIcon } from '../../components/CheckListIcon'
import { DataTable } from '../../components/DataTable'
import { DatePicker } from '../../components/DatePicker'
import { EventHeader } from '../../components/EventHeader'
import { LocationIcon } from '../../components/LocationIcon'
import { Modal } from '../../components/Modal'
import { RichTextInput } from '../../components/RichTextInput'
import { Select } from '../../components/Select'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Images
import Add from '../../assets/images/add.svg'
import Calendar from '../../assets/images/calendar.svg'
import Present from '../../assets/images/present.svg'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import { createSession, getSessionsList } from '../../services/events.service'

// Utils & Styles
import { handlePagination, toast } from '../../utils/helpers'

/**
 *
 * EventSessions
 *
 */
const EventSessions = observer(() => {
  const DEFAULT = {
    name: null,
    type: null,
    date: null,
    startsAt: null,
    endsAt: null,
    location: null,
    numOfDevices: null,
    sessionCode: null,
    creditType: null,
    creditAmount: null,
    scanningNotes: '<p><br></p>',
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: DEFAULT,
  })

  // Context
  const { eventId } = useParams()
  const navigate = useNavigate()
  const { organizationId, event } = useContext(NavigationStoreContext)

  // State
  const [sessions, setSessions] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showDate, setShowDate] = useState(false)

  // Pagination
  const [totalRows, setTotalRows] = useState(0)
  const [pages, setPages] = useState({ next: null, previous: null })
  const [perPage, setPerPage] = useState(10)
  const [loadingSessions, setLoadingSessions] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  // Search
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState('')
  const searchInputRef = useRef(null)

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  /**
   * Gets the updated list of sessions; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedSessionsList = async (url) => {
    const response = await getSessionsList(url, handleErrors, setLoadingSessions, () => {})

    if (response) {
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
      setSessions(response.results)
    }
  }

  const createNewSession = async (data, createAndGoTo) => {
    const response = await createSession(
      eventId,
      {
        ...data,
        startsAt: `${dayjs(data.date).format('YYYY-MM-DD')}T${data.startsAt}${dayjs().format(
          'Z',
        )}`,
        endsAt: `${dayjs(data.date).format('YYYY-MM-DD')}T${data.endsAt}${dayjs().format('Z')}`,
        type: data.type.id,
      },
      handleErrors,
      setLoading,
    )

    if (response) {
      handleSuccess('Session created successfully')
      setShowModal(false)
      reset(DEFAULT)
      if (createAndGoTo) {
        navigate(`/organization/${organizationId}/event/${eventId}/sessions/list/${response.id}/`)
      }
      getUpdatedSessionsList(
        `/events/${eventId}/sessions/?limit=${perPage}${filter ? `&${filter}` : ''}`,
      )
    }
  }

  useEffect(() => {
    if (filter) {
      getUpdatedSessionsList(`/events/${eventId}/sessions/?limit=${perPage}&${filter}`)
    } else {
      getUpdatedSessionsList(`/events/${eventId}/sessions/?limit=${perPage}`)
    }
  }, [filter, perPage])

  /**
   * Updates the search query based on `search`.
   * @param {string} search
   */
  const updateSearch = (search) => {
    let updatedFilter = ''
    if (search) updatedFilter = `q=${search}`

    setFilter(updatedFilter)
  }

  const filterSessions = useCallback(_.debounce(updateSearch, 500), [])

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="flex h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="mb-3 flex flex-col items-start justify-between space-y-1 sm:flex-row sm:items-center sm:space-y-0">
            <EventHeader event={event} />
          </div>

          <span className="text-md font-bold">Sessions</span>

          <div className="flex flex-row items-center justify-between space-x-2">
            <TextInput
              className="w-full rounded-full py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600 md:w-[450px]"
              icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
              id="search"
              endIcon={
                searchTerm ? (
                  <button
                    type="button"
                    onClick={() => {
                      filterSessions('')
                      setSearchTerm('')
                      searchInputRef.current.value = ''
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                  </button>
                ) : null
              }
              name="search"
              onChange={(e) => {
                filterSessions(e.target.value)
                setSearchTerm(e.target.value)
              }}
              placeholder="Looking for something?"
              ref={searchInputRef}
              type="search"
              value={searchTerm}
            />

            <Button
              background="bg-purple border-purple hover:bg-purple-600"
              icon={<PlusIcon className="h-5 sm:h-6" />}
              label="Add Session"
              onClick={() => {
                setShowModal(true)
              }}
            />
          </div>

          <DataTable
            columns={[
              {
                id: 'type',
                name: 'Type',
                selector: (row) => row.type,
                cell: (row) => (
                  <div className="flex items-center space-x-2">
                    <span
                      className={mergeClassNames(
                        'rounded-full px-2.5 py-0.5 text-xs font-bold text-white',
                        row.type === 'Access Control'
                          ? 'bg-status-green text-role-admin'
                          : 'bg-purple',
                      )}
                    >
                      {row.type === 'Access Control' ? 'AC' : 'DC'}
                    </span>
                  </div>
                ),
                sortable: true,
                sortBy: 'type',
                width: '100px',
              },
              {
                id: 'name',
                grow: 1,
                name: 'Session Name',
                selector: (row) => row.name,
                cell: (row) => (
                  <button
                    className="font-bold text-purple hover:text-black"
                    key={`event:${row.id}`}
                    onClick={() => {
                      navigate(
                        `/organization/${organizationId}/event/${eventId}/sessions/list/${row.id}/`,
                      )
                    }}
                    type="button"
                  >
                    {row.name}
                  </button>
                ),
                minWidth: '150px',
                sortable: true,
                sortBy: 'name',
              },
              {
                id: 'date',
                grow: 0.25,
                name: 'Date',
                selector: (row) => dayjs(row.startsAt).format('MM/DD/YYYY'),
                minWidth: '115px',
              },
              {
                id: 'startsAt',
                grow: 0.25,
                name: 'Start Time',
                selector: (row) => dayjs(row.startsAt).format('hh:mm A'),
                minWidth: '115px',
              },
              {
                id: 'endsAt',
                grow: 0.25,
                name: 'End Time',
                selector: (row) => dayjs(row.endsAt).format('hh:mm A'),
                minWidth: '115px',
              },
              {
                id: 'location',
                grow: 0.75,
                name: 'Location',
                selector: (row) => row.location,
                minWidth: '125px',
              },
            ]}
            data={sessions}
            defaultSortFieldId="name"
            defaultSortAsc
            onChangePage={(page) =>
              handlePagination(
                page,
                currentPage,
                perPage,
                totalRows,
                pages,
                setCurrentPage,
                getUpdatedSessionsList,
                `/events/${eventId}/sessions/?limit=`,
                filter,
              )
            }
            onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
            onSort={(column, direction) => {
              const d = direction === 'asc' ? '' : '-'
              getUpdatedSessionsList(
                `/events/${eventId}/sessions/?order_by=${d}${column.sortBy}&limit=${perPage}`,
              )
            }}
            pagination
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
            paginationTotalRows={totalRows}
            paginationServer
            progressPending={loadingSessions}
            sortServer
          />
        </div>
      </StateContainer>

      <Modal
        className="w-full sm:w-[700px]"
        actions={null}
        icon={<img src={Add} alt="Add" />}
        content={
          <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<CheckListIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
              disabled={loading}
              error={errors.name && 'This field is required'}
              fullWidth
              id="name"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="name"
              nunito
              label="Name"
              placeholder="Name"
              {...register('name', { required: true })}
            />

            <div className="mb-1 flex flex-col space-y-4 sm:mb-4 sm:flex-row sm:space-x-2 sm:space-y-0">
              <Controller
                name="type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className="w-full rounded-2xl border-gray-550 px-4 py-2.5 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    icon={<img alt="Present" className="ml-2 h-5" src={Present} />}
                    data-testid="type"
                    disabled={loading}
                    error={errors.type && 'This field is required'}
                    fullWidth
                    inputStyles="rounded-none rounded-t-md font-nunito"
                    label="Type"
                    name="type"
                    nunito
                    onChange={onChange}
                    options={[
                      { label: 'Access Control', id: 'Access Control' },
                      { label: 'Data Collection', id: 'Data Collection' },
                    ]}
                    placeholder="Type"
                    style={{ flex: true, width: '100%' }}
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />

              <Controller
                name="date"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    disabled={loading}
                    error={errors.date && (errors.date.message || 'This field is required')}
                    label="Date"
                    name="Date"
                    onChange={onChange}
                    show={showDate}
                    setShow={setShowDate}
                    value={value}
                  />
                )}
                rules={{
                  required: true,
                }}
              />
            </div>

            <div className="flex flex-col sm:flex-row sm:space-x-2">
              <TextInput
                className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                icon={<img alt="Calendar" className="ml-2 h-5" src={Calendar} />}
                data-testid="startsAt"
                disabled={loading}
                error={errors.startsAt && (errors.startsAt.message || 'This field is required')}
                fullWidth
                id="startsAt"
                inputStyles="rounded-none rounded-t-md font-nunito"
                name="startsAt"
                nunito
                label="Starts At"
                type="time"
                {...register('startsAt', {
                  required: true,
                  validate: (value, values) => {
                    const start = dayjs().hour(value.split(':')[0]).minute(value.split(':')[1])
                    const end =
                      values.endsAt &&
                      dayjs().hour(values.endsAt.split(':')[0]).minute(values.endsAt.split(':')[1])
                    if (end && dayjs(start).isAfter(dayjs(end))) {
                      return 'Start date must be before end date'
                    }

                    return null
                  },
                })}
              />

              <TextInput
                className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                icon={<img alt="Calendar" className="ml-2 h-5" src={Calendar} />}
                data-testid="endsAt"
                disabled={loading}
                error={errors.endsAt && (errors.endsAt.message || 'This field is required')}
                fullWidth
                id="endsAt"
                inputStyles="rounded-none rounded-t-md font-nunito"
                name="endsAt"
                nunito
                label="Ends At"
                type="time"
                {...register('endsAt', {
                  required: true,
                  validate: (value, values) => {
                    const start =
                      values.startsAt &&
                      dayjs()
                        .hour(values.startsAt.split(':')[0])
                        .minute(values.startsAt.split(':')[1])
                    const end = dayjs()
                      .hour(values.endsAt.split(':')[0])
                      .minute(values.endsAt.split(':')[1])
                    if (start && dayjs(start).isAfter(dayjs(end))) {
                      return 'End date must be after start date'
                    }

                    return null
                  },
                })}
              />
            </div>

            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<LocationIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
              data-testid="location"
              disabled={loading}
              error={errors.location && 'This field is required'}
              fullWidth
              id="location"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="location"
              nunito
              label="Location"
              placeholder="Location"
              {...register('location', { required: true })}
            />

            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<LocationIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
              data-testid="numOfDevices"
              disabled={loading}
              fullWidth
              id="numOfDevices"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="numOfDevices"
              nunito
              label="Number of Devices"
              placeholder="Number of Devices"
              type="number"
              {...register('numOfDevices')}
            />

            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<LocationIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
              data-testid="sessionCode"
              disabled={loading}
              fullWidth
              id="sessionCode"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="sessionCode"
              nunito
              label="Session Code"
              placeholder="Session Code"
              {...register('sessionCode')}
            />

            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<LocationIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
              data-testid="creditType"
              disabled={loading}
              fullWidth
              id="creditType"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="creditType"
              nunito
              label="Credit Type"
              placeholder="Credit Type"
              {...register('creditType')}
            />

            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<LocationIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
              data-testid="creditAmount"
              disabled={loading}
              fullWidth
              id="creditAmount"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="creditAmount"
              nunito
              label="Credit Amount"
              placeholder="Credit Amount"
              type="number"
              {...register('creditAmount')}
            />

            <Controller
              name="scanningNotes"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RichTextInput
                  id="scanningNotes"
                  disabled={loading}
                  onChange={onChange}
                  placeholder="Add scanning notes here."
                  value={value}
                />
              )}
            />

            <div className="mt-5 space-y-3 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3 sm:space-y-0">
              <Button
                background="bg-white"
                label="Cancel"
                onClick={() => {
                  setShowModal(false)
                  reset(DEFAULT)
                }}
              />
              <Button
                background="bg-purple border-purple hover:bg-purple-600"
                label="Create Session and Close"
                onClick={handleSubmit((data) => createNewSession(data, false))}
              />
              <Button
                background="bg-purple border-purple hover:bg-purple-600"
                label="Create and Go to Session"
                onClick={handleSubmit((data) => createNewSession(data, true))}
              />
            </div>
          </div>
        }
        loading={loading}
        onClose={() => {
          setShowModal(false)
          reset(DEFAULT)
        }}
        open={showModal}
        setOpen={setShowModal}
        title="Add Session"
      />
    </div>
  )
})

export default EventSessions
