import React, { useEffect, useState } from 'react'
import { useForm, useWatch, Controller } from 'react-hook-form'
import { usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import Autocomplete from 'react-google-autocomplete'
import PropTypes from 'prop-types'
import _ from 'lodash'

// Images
import User from '../../assets/images/user.svg'
import Location from '../../assets/images/location.svg'

// Components
import { EditIcon } from '../EditIcon'
import { Modal } from '../Modal'
import { Select } from '../Select'
import { TextInput } from '../TextInput'

import { calculatePriceTotal, getPricingData, configureBillingAddress } from './helpers'

const PurchaseLicensesModal = ({
  event,
  cancelButtonLabel,
  licenses,
  loading,
  onSubmit,
  showModal,
  setShowModal,
}) => {
  // State
  const [pricingInfo, setPricingInfo] = useState({
    firstPrice: 0,
    additionalPrice: 0,
    rentalPrice: 0,
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm({
    defaultValues: {
      numberOfLicenses: { label: '1', id: 1 },
      numberOfLeadRetrievalDevices: { label: '0', id: 0 },
      firstName: '',
      lastName: '',
      creditCard: '',
      cardExpiry: '',
      cardCvc: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: '',
    },
  })

  const pricingFormData = useWatch({ control })

  const { meta, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs()

  useEffect(() => {
    const pricing = getPricingData(event)
    setPricingInfo(pricing)
  }, [])

  const noPricing = pricingInfo.firstPrice === null && pricingInfo.additionalPrice === null

  const configureSubmitLabel = () => {
    if (noPricing) {
      return 'Pricing Not Set'
    }

    return `Purchase ($${calculatePriceTotal(
      pricingFormData,
      pricingInfo,
      licenses.length,
      licenses.filter((license) => !license.isFreeLicense && !license.isRefunded).length,
    )})`
  }

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: cancelButtonLabel,
          onClick: () => setShowModal(false),
        },
        {
          disabled: noPricing,
          type: 'submit',
          label: configureSubmitLabel(),
          onClick: () => handleSubmit(onSubmit)(),
        },
      ]}
      icon={<EditIcon className="h-5 fill-white sm:h-6" />}
      content={
        <div className="mt-3 flex flex-col gap-4 sm:mt-5">
          <div className="text-sm text-gray-600">
            <span className="text-sm text-gray-600">
              In order to start building your Lead Retrieval App and access your leads, you must
              purchase at least one (1) license. Please select from the below and complete your
              purchase.
            </span>
            <div className="mt-5">
              <span className="font-bold">First License</span> -{' '}
              {pricingInfo.firstPrice !== null ? `$${pricingInfo.firstPrice}` : 'Pricing Not Set'}
            </div>
            <div>
              <span className="font-bold">Additional Licenses</span> -{' '}
              {pricingInfo.additionalPrice !== null
                ? `$${pricingInfo.additionalPrice}`
                : 'Pricing Not Set'}
            </div>
            <div className="mb-5">
              <span className="font-bold">Lead Retrieval Device Rental (optional)*</span> -{' '}
              {pricingInfo.rentalPrice !== null
                ? `$${pricingInfo.rentalPrice}/rental`
                : 'Pricing Not Set'}
            </div>
            <div className="italic">
              *Licenses can be used on your smartphone or by renting a device
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <Controller
              name="numberOfLicenses"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  data-testid="numberOfLicenses"
                  disabled={loading}
                  error={errors.numberOfLicenses && 'This field is required'}
                  fullWidth
                  id="numberOfLicenses"
                  name="numberOfLicenses"
                  nunito
                  onChange={onChange}
                  options={_.map(_.range(0, 51), (i) => ({ id: i, label: `${i}` }))}
                  label="Number of Licenses"
                  placeholder="Select a number of licenses to purchase"
                  style={{ flex: true, width: '100%' }}
                  value={value}
                />
              )}
              rules={{ required: true }}
            />
            <Controller
              name="numberOfLeadRetrievalDevices"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  data-testid="numberOfLeadRetrievalDevices"
                  disabled={loading}
                  error={errors.numberOfLeadRetrievalDevices && 'This field is required'}
                  fullWidth
                  id="numberOfLeadRetrievalDevices"
                  name="numberOfLeadRetrievalDevices"
                  nunito
                  onChange={onChange}
                  options={_.map(_.range(0, 21), (i) => ({ id: i, label: `${i}` }))}
                  label="Number of Lead Retrieval Device Rental(s) (does not include license)"
                  placeholder="Select a number of lead retrieval devices to rent"
                  style={{ flex: true, width: '100%' }}
                  value={value}
                />
              )}
              rules={{ required: true }}
            />
          </div>

          <div className="flex flex-col">
            <span className="text-sm font-medium text-gray-700">Card Details</span>
            <div className="flex gap-2">
              <Controller
                name="firstName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    className="mr-2 mt-2 rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    data-testid="firstName"
                    disabled={loading}
                    error={errors.firstName && 'This field is required'}
                    fullWidth
                    icon={<img alt="User" className="ml-2 h-4" src={User} />}
                    id="firstName"
                    onChange={onChange}
                    name="firstName"
                    placeholder="First Name"
                    style={{ flex: true, width: '100%' }}
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    className="mt-2 rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    data-testid="lastName"
                    disabled={loading}
                    error={errors.lastName && 'This field is required'}
                    fullWidth
                    icon={<img alt="User" className="ml-2 h-4" src={User} />}
                    id="lastName"
                    onChange={onChange}
                    name="lastName"
                    placeholder="Last Name"
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />
            </div>

            <div className="mt-2 flex items-center rounded-2xl border border-gray-550 py-2.5 pl-2 pr-4 focus-within:border-purple">
              <div className="basis-2">
                <svg {...getCardImageProps({ images })} />
              </div>
              <Controller
                name="cardNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    {...getCardNumberProps({ onChange })}
                    containerClassName="w-full"
                    className="sentry-mask border-0 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    data-testid="cardNumber"
                    disabled={loading}
                    id="cardNumber"
                    name="cardNumber"
                    placeholder="Card number"
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />
              <Controller
                name="cardExpiry"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    {...getExpiryDateProps({ onChange })}
                    containerClassName="basis-26"
                    className="sentry-mask border-0 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    data-testid="cardExpiry"
                    disabled={loading}
                    id="cardExpiry"
                    name="cardExpiry"
                    placeholder="MM / YY"
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />
              <Controller
                name="cardCvc"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    {...getCVCProps({ onChange })}
                    containerClassName="basis-26"
                    className="sentry-mask border-0 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    data-testid="cardCvc"
                    disabled={loading}
                    id="cardCvc"
                    name="cardCvc"
                    placeholder="CVC"
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
            {meta.isTouched && meta.error && (
              <div className="mt-1 w-full bg-error-light px-2 py-1">
                <p className="text-center text-sm font-medium text-error-dark">{meta.error}</p>
              </div>
            )}

            {(errors.cardCvc || errors.cardExpiry || errors.cardNumber) && (
              <div className="mt-1 w-full bg-error-light px-2 py-1">
                <p className="text-center text-sm font-medium text-error-dark">
                  This field is required
                </p>
              </div>
            )}

            <div className="mt-5">
              <span className="text-sm font-medium text-gray-700">Billing Address</span>
              <div className="relative mt-2">
                <img
                  alt="Location"
                  className="absolute left-1 top-2.5 ml-1.5 h-5"
                  src={Location}
                />
                <Autocomplete
                  data-testid="billingAddress"
                  className="w-full rounded-2xl border border-gray-550 py-2.5 pl-8 pr-4 text-sm outline-none placeholder:text-gray-600 focus-within:border-purple"
                  apiKey={import.meta.env.VITE_GOOGLE_API_KEY}
                  id="address"
                  name="address"
                  onPlaceSelected={(place) => {
                    // Set the address fields
                    const addressComponents = configureBillingAddress(place)
                    setValue(
                      'address1',
                      `${addressComponents.streetNumber} ${addressComponents.streetName}`,
                    )
                    setValue('city', addressComponents.city)
                    setValue('state', addressComponents.state)
                    setValue('country', addressComponents.country)
                    setValue('zip', addressComponents.zip)
                  }}
                  options={{
                    types: ['address'],
                  }}
                  placeholder="Enter billing address"
                />
              </div>
            </div>

            <div className="my-2 h-[1px] w-[80%] place-self-center bg-gray-400" />

            <div className="flex flex-col gap-2">
              <TextInput
                fullWidth
                className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                error={errors.address1 && 'This field is required'}
                name="address1"
                placeholder="Address"
                {...register('address1', { required: true })}
              />

              <TextInput
                fullWidth
                className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                error={errors.address2 && 'This field is required'}
                name="address2"
                placeholder="Apartment, Suite, etc."
                {...register('address2')}
              />
              <div className="flex flex-col sm:flex-row sm:space-x-2">
                <TextInput
                  fullWidth
                  className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  error={errors.city && 'This field is required'}
                  name="city"
                  placeholder="City"
                  {...register('city', { required: true })}
                />
                <TextInput
                  fullWidth
                  className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  error={errors.state && 'This field is required'}
                  name="state"
                  placeholder="State"
                  {...register('state', { required: true })}
                />
              </div>
              <div className="flex flex-col sm:flex-row sm:space-x-2">
                <TextInput
                  fullWidth
                  className="w-full rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  error={errors.country && 'This field is required'}
                  name="country"
                  placeholder="Country"
                  {...register('country', { required: true })}
                />

                <TextInput
                  fullWidth
                  className="w-full rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  error={errors.zip && 'This field is required'}
                  name="zip"
                  placeholder="Zip Code"
                  {...register('zip', { required: true })}
                />
              </div>
            </div>
          </div>
        </div>
      }
      loading={loading}
      open={showModal}
      setOpen={setShowModal}
      title="Purchase Licenses"
    />
  )
}

PurchaseLicensesModal.defaultProps = {
  cancelButtonLabel: 'Cancel',
  loading: false,
}

PurchaseLicensesModal.propTypes = {
  cancelButtonLabel: PropTypes.string,
  event: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  licenses: PropTypes.array.isRequired,
}

export default PurchaseLicensesModal
