import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ClipboardDocumentListIcon, PlusIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../../components/Button'
import { Modal } from '../../components/Modal'
import { Select } from '../../components/Select'
import { TextInput } from '../../components/TextInput'
import { Toggle } from '../../components/Toggle'

// Images
import Add from '../../assets/images/add.svg'
import Edit from '../../assets/images/editCircle.svg'

// Service
import { updateSession } from '../../services/events.service'

// Utils & Styles
import { INCLUSION_OPTIONS, OPERATOR_OPTIONS, NON_SYNCED_FIELDS } from '../../utils/constants'
import { toast } from '../../utils/helpers'

const DEFAULT_LOGIC = {
  inclusionLogic: INCLUSION_OPTIONS[0],
  mergeField: null,
  operator: null,
  value: null,
}

const SessionOptions = ({ event, loading, session, setSession }) => {
  const mergeFields =
    event.enableRegistrationSync && event.enabledCustomFields
      ? [...NON_SYNCED_FIELDS, ...event.enabledCustomFields]
      : NON_SYNCED_FIELDS

  // State
  const [filteredMergeFields, setFilteredMergeFields] = useState(mergeFields)
  const [showMergeFieldModal, setShowMergeFieldModal] = useState(false)
  const [showAccessControlModal, setShowAccessControlModal] = useState(false)
  const [loadingUpdatedSession, setLoadingUpdatedSession] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      accessControlLogic: [DEFAULT_LOGIC],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'accessControlLogic',
    rules: { required: true },
  })

  useEffect(() => {
    if (session.accessControlLogic.length > 0) {
      reset({
        inclusionLogic: INCLUSION_OPTIONS.find(
          (o) => o.id === session.accessControlLogic[0].inclusionLogic,
        ),
        accessControlLogic: _.map(session.accessControlLogic, (l) => ({
          ...l,
          inclusionLogic: INCLUSION_OPTIONS.find((o) => o.id === l.inclusionLogic),
          mergeField: MERGE_FIELD_OPTIONS.find((o) => o.label === l.mergeField),
          operator: _.find(OPERATOR_OPTIONS, (o) => o.id === l.operator),
          value:
            event.availableCustomFields &&
            event.availableCustomFields[l.mergeField]?.options &&
            event.availableCustomFields[l.mergeField]?.options.length > 0
              ? { id: l.value, label: l.value }
              : l.value,
        })),
      })
    }
  }, [session, event])

  const MERGE_FIELD_OPTIONS = _.map(mergeFields, (f) => {
    const matchingCustomFieldId = _.find(
      _.keys(event.availableCustomFields),
      (o) => event.availableCustomFields[o].text === f,
    )
    return { label: f, id: matchingCustomFieldId || f, baseField: !matchingCustomFieldId }
  })

  const inclusionLogicButtons = useMemo(() => {
    if (fields.length === 5) return null

    if (fields.length === 1) {
      return (
        <div className="flex flex-row gap-4">
          <Button
            className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
            icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
            label="AND"
            onClick={() => {
              append(DEFAULT_LOGIC)
              setValue('inclusionLogic', INCLUSION_OPTIONS[0])
            }}
          />

          <Button
            className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
            icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
            label="OR"
            onClick={() => {
              append({ ...DEFAULT_LOGIC, inclusionLogic: INCLUSION_OPTIONS[1] })
              setValue('inclusionLogic', INCLUSION_OPTIONS[1])
              setValue(`accessControlLogic.0.inclusionLogic`, INCLUSION_OPTIONS[1])
            }}
          />
        </div>
      )
    }

    if (getValues('accessControlLogic.0.inclusionLogic')?.id === 'AND') {
      return (
        <Button
          className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
          icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
          label="AND"
          onClick={() => append(DEFAULT_LOGIC)}
        />
      )
    }

    return (
      <Button
        className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
        icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
        label="OR"
        onClick={() => append({ ...DEFAULT_LOGIC, inclusionLogic: INCLUSION_OPTIONS[1] })}
      />
    )
  }, [fields, watch('accessControlLogic.0.inclusionLogic')])

  // Ref
  const searchInputRef = useRef()

  const handleChanges = async (data) => {
    const updatedSession = {
      ...data,
      eventId: event.id,
      id: session.id,
    }

    await updateSession(updatedSession, handleError, setLoadingUpdatedSession, (m) => {
      handleSuccess(m)
    })
  }

  const handleMessageChanges = _.debounce(handleChanges, 1000)

  const renderValueField = (key, i) => {
    const mergeField = watch(`${key}.mergeField`)
    if (
      !mergeField ||
      mergeField.baseField ||
      !event.availableCustomFields[mergeField.id]?.options ||
      event.availableCustomFields[mergeField.id]?.options.length === 0
    ) {
      return (
        <TextInput
          className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
          data-testid={`${key}.value`}
          disabled={loadingUpdatedSession || watch(`${key}.operator`)?.id === 'populated'}
          error={
            errors.accessControlLogic &&
            errors.accessControlLogic[i]?.value &&
            'This field is required'
          }
          fullWidth
          id={`${key}.value`}
          inputStyles="rounded-none"
          name={`${key}.value`}
          nunito
          label="Value"
          placeholder="Value"
          {...register(`${key}.value`, {
            required: watch(`${key}.operator`)?.id !== 'populated',
          })}
        />
      )
    }
    const options = _.map(event.availableCustomFields[mergeField?.id].options, (o) => ({
      id: o,
      label: o,
    }))
    const selectedId = getValues(`${key}.value`)?.id || getValues(`${key}.value`)
    const value = _.find(options, (o) => o.id === selectedId) || null

    return (
      <Controller
        name={`${key}.value`}
        control={control}
        render={() => (
          <Select
            className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            data-testid={`${key}.value`}
            disabled={loadingUpdatedSession || watch(`${key}.operator`)?.id === 'populated'}
            error={
              errors.accessControlLogic &&
              errors.accessControlLogic[i]?.value &&
              'This field is required'
            }
            fullWidth
            id={`${key}.value`}
            label="Value"
            name={`${key}.value`}
            nunito
            onChange={(e) => {
              setValue(`${key}.value`, e)
              clearErrors(`${key}.value`)
            }}
            options={options}
            placeholder="Select an Option"
            search
            style={{ flex: true, width: '100%' }}
            value={value}
          />
        )}
        rules={{
          required: watch(`${key}.operator`)?.id !== 'populated',
        }}
      />
    )
  }

  return (
    <div className="flex h-full w-full flex-col gap-5 overflow-y-auto px-9">
      <div className="mt-9 text-xl font-bold">All Session Options</div>
      <div className="mb-8 flex flex-col gap-8 lg:mb-0 lg:flex-row">
        <div className="space-y-2 lg:basis-3/5">
          <span className="font-bold">Session Insights</span>
          <Toggle
            label="Enable Insight"
            checked={session.enableInsights}
            disabled={loading}
            onChange={async (e) => {
              const updatedSession = { ...session, enableInsights: e }
              await handleChanges({ enableInsights: e })
              setSession(updatedSession)
            }}
          />
          {session.enableInsights && (
            <>
              <TextInput
                className="rounded-2xl border-gray-550 px-4 py-2.5 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                data-testid="insightsMessage"
                disabled={loading}
                error={errors.name}
                id="insightsMessage"
                inputStyles="rounded-none rounded-t-md font-nunito"
                name="insightsMessage"
                onChange={async (e) => {
                  const updatedSession = { ...session, insightsMessage: e.target.value }
                  await handleMessageChanges({ insightsMessage: e.target.value })
                  setSession(updatedSession)
                }}
                value={session.insightsMessage}
              />
              <Button
                background="bg-purple-100"
                className="border-purple-100 text-purple-700"
                label="Merge Fields"
                onClick={() => setShowMergeFieldModal(true)}
              />
            </>
          )}
        </div>
        <div className="flex flex-col space-y-2 lg:basis-2/5">
          <span className="font-bold">Session Check-out</span>
          <Toggle
            label="Session Check-out"
            checked={session.enableCheckOut}
            disabled={loading}
            onChange={async (e) => {
              const updatedSession = { ...session, enableCheckOut: e }
              await handleChanges({ enableCheckOut: e })
              setSession(updatedSession)
            }}
          />

          <span className="font-bold">Photo Verification</span>
          <Toggle
            label="Enable Photo Verification"
            checked={session.enablePhotoVerification}
            disabled={loading}
            onChange={async (e) => {
              const updatedSession = { ...session, enablePhotoVerification: e }
              await handleChanges({ enablePhotoVerification: e })
              setSession(updatedSession)
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-8 pb-8 lg:flex-row">
        {session.type === 'Access Control' && (
          <>
            <div className="flex flex-col gap-4 lg:basis-3/5">
              <div className="flex items-center justify-between">
                <span className="text-xl font-bold">Access Control Options</span>
                <Button
                  background="bg-purple border-purple hover:bg-purple-600"
                  label={
                    session.accessControlLogic.length > 0
                      ? 'Edit Access Logic'
                      : 'Add Access Logic'
                  }
                  onClick={() => setShowAccessControlModal(true)}
                />
              </div>

              <div className="flex flex-col gap-2">
                {_.map(session.accessControlLogic, (l, i) => (
                  <div
                    className={mergeClassNames(
                      'flex flex-row items-center justify-between gap-4',
                      i !== session.accessControlLogic.length - 1 &&
                        'border-b border-gray-300 pb-2',
                    )}
                    key={l.id}
                  >
                    <span className="flex flex-row gap-2">
                      <span>{`{{ ${l.mergeField} }}`}</span>
                      <span>{l.operator}</span>
                      <span className="font-bold">{l.value}</span>
                    </span>
                    {i !== session.accessControlLogic.length - 1 && (
                      <span className="flex items-center rounded-full bg-purple-100 px-2.5 py-1 text-xs font-bold text-purple">
                        {l.inclusionLogic}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-12 space-y-2 lg:basis-2/5">
              <span className="font-bold">Session Override</span>
              <Toggle
                label="Enable in-app registration"
                checked={session.enableSessionOverride}
                disabled={loading}
                onChange={async (e) => {
                  const updatedSession = { ...session, enableSessionOverride: e }
                  await handleChanges({ enableSessionOverride: e })
                  setSession(updatedSession)
                }}
              />
            </div>
          </>
        )}
      </div>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Close',
            onClick: () => {
              setFilteredMergeFields(mergeFields)
              setShowMergeFieldModal(false)
            },
          },
        ]}
        icon={<ClipboardDocumentListIcon className="h-5 stroke-white sm:h-6" />}
        content={
          <div className="mt-3 flex max-h-56 flex-col sm:mt-5">
            <TextInput
              autoComplete="off"
              className="w-[90%] self-center rounded-lg py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600"
              icon={<MagnifyingGlassIcon className="ml-2 h-5 text-purple" aria-hidden="true" />}
              id="search"
              endIcon={
                searchTerm ? (
                  <button
                    type="button"
                    onClick={() => {
                      setFilteredMergeFields(mergeFields)
                      setSearchTerm('')
                      searchInputRef.current.value = ''
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                  </button>
                ) : null
              }
              name="search"
              onChange={(e) => {
                setFilteredMergeFields(
                  _.filter(mergeFields, (o) => o.toLowerCase().includes(e.target.value)),
                )

                setSearchTerm(e.target.value)
              }}
              placeholder="Search"
              ref={searchInputRef}
              type="text"
              value={searchTerm}
            />

            <div className="mx-8 mt-2 flex flex-col overflow-y-scroll">
              {_.map(filteredMergeFields, (f, i) => (
                <button
                  className="group flex cursor-pointer flex-row"
                  key={`${i}`}
                  onClick={() => {
                    navigator.clipboard.writeText(`{{ ${f} }}`)
                    handleSuccess('Merge field copied to clipboard!')
                  }}
                  type="button"
                >
                  <span className="group-hover:text-purple">{f}</span>

                  <ClipboardDocumentListIcon className="hidden h-6 group-hover:flex group-hover:stroke-purple" />
                </button>
              ))}
            </div>
          </div>
        }
        open={showMergeFieldModal}
        title="Copy Merge Field"
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Close',
            onClick: () => {
              reset()
              setShowAccessControlModal(false)
            },
          },
          {
            type: 'submit',
            label: 'Submit',
            onClick: handleSubmit(async (data) => {
              const logic = _.map(data.accessControlLogic, (l) => ({
                ...l,
                mergeField: l.mergeField.label,
                operator: l.operator.id,
                inclusionLogic: l.inclusionLogic.id,
                value: l.value?.id || l.value,
              }))

              await handleChanges({ accessControlLogic: logic })
              setShowAccessControlModal(false)
              setSession({ ...session, accessControlLogic: logic })
            }),
          },
        ]}
        icon={
          <img
            src={session.accessControlLogic.length > 0 ? Edit : Add}
            alt={session.accessControlLogic.length > 0 ? 'Edit' : 'Add'}
          />
        }
        content={
          <div className="mb-10 flex h-full w-full flex-col gap-4">
            <div className="flex w-full flex-col gap-4">
              {_.map(fields, (field, i) => {
                const key = `accessControlLogic.${i}`
                return (
                  <div className="flex w-full flex-row" key={field.id}>
                    <div
                      className={mergeClassNames(
                        'flex h-full w-9 shrink-0 flex-col items-center justify-center gap-1',
                        i === 0 && 'w-0',
                        i !== 0 && 'mr-4',
                      )}
                    >
                      {i > 0 && (
                        <span className="rounded-lg bg-gray-200 p-1 text-xs font-semibold text-gray-700">
                          {getValues('inclusionLogic')?.label}
                        </span>
                      )}

                      {i !== 0 && (
                        <Button
                          icon={<XCircleIcon className="h-6 stroke-inherit" />}
                          className="rounded-full stroke-red p-0 hover:stroke-red-600"
                          iconOnly
                          onClick={() => remove(i)}
                        />
                      )}
                    </div>

                    <div className="flex w-full flex-row gap-4">
                      <Controller
                        name={`${key}.mergeField`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                            data-testid={`${key}.mergeField`}
                            disabled={loadingUpdatedSession}
                            error={
                              errors.accessControlLogic &&
                              errors.accessControlLogic[i]?.operator &&
                              'This field is required'
                            }
                            fullWidth
                            id={`${key}.mergeField`}
                            label="Merge Field"
                            name={`${key}.mergeField`}
                            nunito
                            onChange={(e) => {
                              // Reset logic field value
                              setValue(`${key}.value`, null)

                              // Update the merge field
                              onChange(e)
                            }}
                            options={MERGE_FIELD_OPTIONS}
                            placeholder="Select a Merge Field"
                            search
                            style={{ flex: true, width: '100%' }}
                            value={value}
                          />
                        )}
                        rules={{ required: true }}
                      />

                      <Controller
                        name={`${key}.operator`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                            data-testid={`${key}.operator`}
                            disabled={loadingUpdatedSession}
                            error={
                              errors.accessControlLogic &&
                              errors.accessControlLogic[i]?.operator &&
                              'This field is required'
                            }
                            fullWidth
                            id={`${key}.operator`}
                            label="Operator"
                            name={`${key}.operator`}
                            nunito
                            onChange={onChange}
                            options={OPERATOR_OPTIONS}
                            placeholder="Select an Operator"
                            style={{ flex: true, width: '100%' }}
                            value={value}
                          />
                        )}
                        rules={{ required: true }}
                      />

                      {renderValueField(key, i)}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="flex w-full flex-row justify-center">{inclusionLogicButtons}</div>
          </div>
        }
        className="w-[90%] sm:max-w-screen-md"
        open={showAccessControlModal}
        title={session.accessControlLogic.length > 0 ? 'Edit' : 'Add Access Logic'}
      />
    </div>
  )
}

SessionOptions.propTypes = {
  event: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  session: PropTypes.object.isRequired,
  setSession: PropTypes.func.isRequired,
}

export { SessionOptions }
