import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import dayjs from 'dayjs'
import _ from 'lodash'
import { Controller, useForm } from 'react-hook-form'

// Components
import { CheckListIcon } from '../../components/CheckListIcon'
import { DatePicker } from '../../components/DatePicker'
import { EventHeader } from '../../components/EventHeader'
import { LocationIcon } from '../../components/LocationIcon'
import { RichTextInput } from '../../components/RichTextInput'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Local Components
import { SessionOptions } from './SessionOptions'

// Images
import Calendar from '../../assets/images/calendar.svg'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import { getSession, updateSession } from '../../services/events.service'

// Utils & Styles
import { joinClassNames, toast } from '../../utils/helpers'

const TABS = [
  {
    id: 0,
    label: 'Session Information',
  },
  {
    id: 1,
    label: 'Session Options',
  },
  {
    id: 3,
    label: 'Attendees',
  },
]

/**
 *
 * EventSessionDetail
 *
 */
const EventSessionDetail = observer(() => {
  // Context
  const { event, eventId } = useContext(NavigationStoreContext)
  const { sessionId } = useParams()

  // State
  const [loadingSession, setLoadingSession] = useState(false)

  const [session, setSession] = useState(null)
  const [active, setActive] = useState(TABS[0])
  const [showDate, setShowDate] = useState(false)
  const [disableRegistrationSyncFields, setDisableRegistrationSyncFields] = useState(false)

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  const DEFAULT = {
    name: null,
    type: null,
    date: null,
    startsAt: null,
    endsAt: null,
    location: null,
    numOfDevices: null,
    sessionCode: null,
    creditType: null,
    creditAmount: null,
    scanningNotes: '<p><br></p>',
  }

  const {
    control,
    formState: { dirtyFields, errors },
    getValues,
    register,
    reset,
    setValue,
  } = useForm({
    mode: 'onBlur',
    shouldUnregister: false,
    defaultValues: DEFAULT,
  })

  /**
   * Gets the session.
   */
  const getUpdatedSession = async () => {
    const response = await getSession(
      eventId,
      sessionId,
      handleErrors,
      setLoadingSession,
      () => {},
    )

    if (response) {
      const sessionData = {
        ...response,
        date: dayjs(response.startsAt).format('YYYY-MM-DD'),
        startsAt: dayjs(response.startsAt).format('HH:mm'),
        endsAt: dayjs(response.endsAt).format('HH:mm'),
      }

      setSession(sessionData)
      reset(sessionData, { keepDefaultValues: false })
    }
  }

  /**
   * Loads session details and disable fields if registration sync is enabled.
   */
  useEffect(() => {
    getUpdatedSession()
    setDisableRegistrationSyncFields(event.enableRegistrationSync)
  }, [])

  /**
   * Updates the session info.
   */
  const handleSubmit = async () => {
    const data = getValues()

    // Pull out dirty fields so we only update what's necessary
    const dirtyData = _.pickBy(data, (value, key) => dirtyFields[key])

    // If no fields are dirty, don't process request
    if (_.isEmpty(dirtyData)) return

    const updatedData = { ...dirtyData }

    const updatedSession = {
      ...updatedData,
      startsAt: `${dayjs(data.date).format('YYYY-MM-DD')}T${data.startsAt}${dayjs().format('Z')}`,
      endsAt: `${dayjs(data.date).format('YYYY-MM-DD')}T${data.endsAt}${dayjs().format('Z')}`,
      eventId,
      id: sessionId,
    }

    const response = await updateSession(updatedSession, handleErrors, () => {}, handleSuccess)
    const sessionData = {
      ...response,
      date: dayjs(response.date).format('YYYY-MM-DD'),
      startsAt: dayjs(response.startsAt).format('HH:mm'),
      endsAt: dayjs(response.endsAt).format('HH:mm'),
    }

    setSession(sessionData)
  }

  const renderTabContent = () => {
    switch (active.id) {
      case 0:
        return (
          <div className="flex h-full w-full flex-col gap-5 overflow-y-auto px-9">
            <div className="flex flex-col gap-4">
              <div className="flex justify-end">
                <div
                  role="group"
                  aria-label="Session type"
                  className="ml-4 flex items-center gap-2 rounded-xl bg-blue-800/10 p-1"
                >
                  <button
                    type="button"
                    aria-pressed={session.type === 'Access Control'}
                    aria-label="AC"
                    className={`rounded-lg px-2 py-0.5 ${
                      session.type === 'Access Control'
                        ? 'bg-status-green text-role-admin shadow-md'
                        : 'bg-transparent'
                    }`}
                    onClick={async () => {
                      const updatedSession = {
                        id: session.id,
                        eventId,
                        type: 'Access Control',
                      }
                      const response = await updateSession(
                        updatedSession,
                        handleErrors,
                        () => {},
                        handleSuccess,
                      )

                      if (response) {
                        setSession(response)
                      }
                    }}
                  >
                    AC
                  </button>
                  <button
                    type="button"
                    aria-pressed={session.type === 'Data Collection'}
                    aria-label="DC"
                    className={`rounded-lg px-2 py-0.5 ${
                      session.type === 'Data Collection'
                        ? 'bg-purple text-white shadow-md'
                        : 'bg-transparent'
                    }`}
                    onClick={async () => {
                      const updatedSession = {
                        id: session.id,
                        eventId,
                        type: 'Data Collection',
                      }
                      const response = await updateSession(
                        updatedSession,
                        handleErrors,
                        () => {},
                        handleSuccess,
                      )

                      if (response) {
                        setSession(response)
                      }
                    }}
                  >
                    DC
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-4 lg:flex-row">
                <TextInput
                  className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  containerClassName="w-full"
                  data-testid="name"
                  disabled={loadingSession || disableRegistrationSyncFields}
                  error={errors.name && (errors.name?.message || 'This field is required')}
                  icon={<CheckListIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
                  id="name"
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  label="Name"
                  name="name"
                  {...register('name', { required: true })}
                  onBlur={handleSubmit}
                />

                <Controller
                  name="date"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      disabled={loadingSession || disableRegistrationSyncFields}
                      error={errors.date && (errors.date.message || 'This field is required')}
                      label="Date"
                      name="Date"
                      onChange={onChange}
                      show={showDate}
                      setShow={setShowDate}
                      value={value}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />

                <TextInput
                  className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  containerClassName="w-full"
                  data-testid="startsAt"
                  disabled={loadingSession || disableRegistrationSyncFields}
                  icon={<img alt="Calendar" className="ml-2 h-5" src={Calendar} />}
                  id="startsAt"
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  label="Start Time"
                  name="startsAt"
                  type="time"
                  {...register('startsAt', { required: true })}
                  onBlur={handleSubmit}
                />
              </div>

              <div className="flex flex-col gap-4 lg:flex-row">
                <TextInput
                  className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  containerClassName="w-full"
                  data-testid="endsAt"
                  disabled={loadingSession || disableRegistrationSyncFields}
                  icon={<img alt="Calendar" className="ml-2 h-5" src={Calendar} />}
                  id="endsAt"
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  label="End Time"
                  name="endsAt"
                  type="time"
                  {...register('endsAt', { required: true })}
                  onBlur={handleSubmit}
                />

                <TextInput
                  className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  containerClassName="w-full"
                  data-testid="location"
                  disabled={loadingSession}
                  icon={<LocationIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
                  id="location"
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  label="Location"
                  name="location"
                  placeholder="Location"
                  {...register('location', { required: true })}
                  onBlur={handleSubmit}
                />
              </div>
            </div>

            <div className="flex w-full flex-col gap-4">
              <div className="text-lg font-bold">Optional Session Info</div>

              <div className="flex flex-col items-center gap-4 lg:flex-row">
                <TextInput
                  className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  containerClassName="w-full"
                  data-testid="numOfDevices"
                  disabled={loadingSession}
                  icon={<LocationIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
                  id="numOfDevices"
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  label="Number of Devices"
                  name="numOfDevices"
                  placeholder="Number of Devices"
                  {...register('numOfDevices')}
                  onBlur={handleSubmit}
                />

                <TextInput
                  className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  containerClassName="w-full"
                  data-testid="capacity"
                  disabled={loadingSession}
                  icon={<LocationIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
                  id="capacity"
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  label="Session Capacity"
                  name="capacity"
                  placeholder="Session Capacity"
                  {...register('capacity')}
                  onBlur={handleSubmit}
                />

                <TextInput
                  className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  containerClassName="w-full"
                  data-testid="sessionCode"
                  disabled={loadingSession}
                  icon={<LocationIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
                  id="sessionCode"
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  label="Session Code"
                  name="sessionCode"
                  placeholder="Session Code"
                  {...register('sessionCode')}
                  onBlur={handleSubmit}
                />
              </div>

              <div className="flex space-x-2">
                <TextInput
                  className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  containerClassName="w-full"
                  data-testid="creditType"
                  disabled={loadingSession}
                  icon={<LocationIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
                  id="creditType"
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  label="Credit Type"
                  name="creditType"
                  placeholder="Credit Type"
                  {...register('creditType')}
                  onBlur={handleSubmit}
                />

                <TextInput
                  className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  containerClassName="w-full"
                  data-testid="creditAmount"
                  disabled={loadingSession}
                  icon={<LocationIcon className="ml-2 h-5 stroke-purple" aria-hidden="true" />}
                  id="creditAmount"
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  label="Credit Amount"
                  name="creditAmount"
                  placeholder="Credit Amount"
                  type="number"
                  {...register('creditAmount')}
                  onBlur={handleSubmit}
                />
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <span className="text-sm font-medium text-gray-700">Scanning Notes</span>
              <RichTextInput
                className="rounded-lg border-gray-550 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                disabled={loadingSession}
                defaultValue="<p><br></p>"
                id="scanningNotes"
                onChange={(e) => {
                  setValue('scanningNotes', e, { shouldDirty: true })
                  handleSubmit()
                }}
                placeholder="Add scanning notes here"
                toolbarStyles="border-gray-550"
                value={session.scanningNotes || '<p><br></p>'}
              />
            </div>
          </div>
        )
      case 1:
        return (
          <SessionOptions
            event={event}
            loading={loadingSession}
            session={session}
            setSession={setSession}
          />
        )
      case 3:
        return <div>Attendees</div>
      default:
        return null
    }
  }

  if (!session) {
    return null
  }

  return (
    <div className="h-full w-full">
      <StateContainer loading={loadingSession}>
        <div className="flex h-full w-full flex-col overflow-y-auto p-3">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col items-start justify-between space-y-1 px-3 sm:flex-row sm:space-y-0 md:items-center">
              <EventHeader event={event} />
            </div>

            <div className="flex items-center justify-center gap-3 px-3">
              <span className="text-2xl font-bold">{session.name}</span>
              <span
                className={mergeClassNames(
                  'flex items-center rounded-full px-2.5 py-2 text-xs font-bold text-white shadow-sm',
                  session.type === 'Access Control'
                    ? 'bg-status-green text-role-admin'
                    : 'bg-purple',
                )}
              >
                {session.type}
              </span>

              <span className="text-sm font-semibold">Trackr ID: {session.trackrId}</span>
            </div>
            <div className="px-3">
              <nav className="flex" aria-label="Tabs">
                {TABS.map((tab) => {
                  const activeTab = active.id === tab.id

                  return (
                    <button
                      type="button"
                      key={tab.label}
                      onClick={() => setActive(tab)}
                      className={joinClassNames(
                        activeTab
                          ? 'border-purple-700 bg-[#8c56f626] text-purple-700'
                          : 'border-b-transparent text-gray-600 hover:border-purple-700 hover:bg-[#8c56f626] hover:text-purple-700',
                        'group relative flex w-1/3 items-center justify-center rounded-t-[10px] border-b-2 px-4 py-2 text-sm font-medium',
                      )}
                      aria-current={activeTab ? 'page' : undefined}
                    >
                      {tab.label}
                    </button>
                  )
                })}
              </nav>
            </div>
          </div>

          <div className="shadow-xm flex h-[70%] w-full flex-col overflow-hidden rounded-[15px] bg-white px-5 py-4 tall:h-auto">
            {renderTabContent()}
          </div>
        </div>
      </StateContainer>
    </div>
  )
})

export default EventSessionDetail
